import React, { Component } from "react";

import Ink from "react-ink";
import { Link } from "react-router-dom";

import PWAInstallation from "../PWAInstallation";
import { IMAGES_URL } from "../../../configs/website";

class Nav extends Component {
	static contextTypes = {
		router: () => null,
	};

	render() {
		// Recuperar e validar userSetAddress do localStorage
		const userSetAddressStr = localStorage.getItem("userSetAddress");
		let userSetAddress = null;

		try {
			userSetAddress = userSetAddressStr ? JSON.parse(userSetAddressStr) : null;
		} catch (error) {
			console.error("Error parsing userSetAddress from localStorage:", error);
		}

		const address = userSetAddress && userSetAddress.address ? userSetAddress.address : null;
		const tag = userSetAddress && userSetAddress.tag ? userSetAddress.tag : null;
		const house = userSetAddress && userSetAddress.house ? userSetAddress.house : null;

		return (
			<React.Fragment>
				<div className="col-12 p-0 sticky-top">
					<div className="block m-0">
						<div className="block-content p-0">
							<div className="input-group search-box">
								{!this.props.disable_back_button && (
									<div className="input-group-prepend">
										<button
											type="button"
											className="btn search-navs-btns"
											style={{ position: "relative" }}
											onClick={() => this.context.router.history.goBack()}
										>
											<i className="si si-arrow-left" />
											<Ink duration="500" />
										</button>
									</div>
								)}
								<p className="form-control search-input">
									{this.props.logo &&
										(this.props.logoLink ? (
											<Link to="/">
												<img
													src={IMAGES_URL + process.env.REACT_APP_IMAGE_LOGO + "/public"}
													alt={localStorage.getItem("storeName")}
													className="store-logo"
													width="110px"
													height="37px"
												/>
											</Link>
										) : (
											<img
												src={IMAGES_URL + process.env.REACT_APP_IMAGE_LOGO + "/public"}
												alt={localStorage.getItem("storeName")}
												className="store-logo"
												width="110px"
												height="37px"
											/>
										))}
								</p>
								<div className="input-group-append">
									<PWAInstallation type={"header"} />
									<button
										type="submit"
										className="btn nav-location truncate-text"
										style={{ position: "relative", maxWidth: window.innerWidth - 130 }}
										onClick={() => {
											this.context.router.history.push("/search-location");
										}}
									>
										{address ? (
											<React.Fragment>
												{userSetAddress.businessLocation ? (
													<span>{address}</span>
												) : (
													<span>
														{tag && (
															<strong className="text-uppercase mr-1">{tag}</strong>
														)}
														{address.length > 20 ? (
															`${address.substring(0, 20)} - N° ${house || "S/N"}`
														) : (
															address
														)}
													</span>
												)}
											</React.Fragment>
										) : (
											<span>
												{localStorage.getItem("selectYourAddressToSeeStoresText") ||
													"Selecionar Endereço"}
											</span>
										)}
										<i
											className="si si-arrow-right nav-location-icon ml-1"
											style={{ color: localStorage.getItem("storeColor") }}
										/>
										<Ink duration="500" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Nav;
